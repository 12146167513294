import React from "react";
import "./App.css";

export default function App() {
  return (
      <div className="companyContainer">
        <div className="companyIntro">
          <h1 className="companyName">Punchcard Mafia LLC</h1>
          <img loading="lazy" srcSet="./punchcardlogo.png" className="companyLogo" alt="Punchcard Logo" />
          <p className="introText">
            Hi.
            <br />
            I’m Marcus Posey, founder of Punchcard Mafia, a software company based in New Jersey. You probably found
            this site because you need to verify that the company is not, in fact, a perpetrator of organized crime. Or
            maybe you’re looking for that sweet, sweet NAICS code (it’s 561990). So I’ll try to respect your time by
            keeping things concise.
          </p>
        </div>
        <div className="companyDetails">
          <div className="whoSection">
            <p className="sectionTitle">Who</p>
            <p className="sectionContent">
              Punchcard Mafia LLC (henceforth referred to, rather affectionately of course, as “The Company”) is a limited
              liability company. It contains one member: Marcus Posey. He has been developing software for 16 years and is
              relatively good at it, all things considered.
            </p>
          </div>
          <div className="whatSection">
            <p className="sectionTitle">What</p>
            <p className="sectionContent">
              The Company is building a yet-to-be-released website in the adult entertainment industry that will improve
              the experience of individuals buying and selling adult content online (such as pornographic photos and
              videos). This is an extension of the core beliefs of the company:
              <br />
              <br />
              - Status quo? Status schmo.
              <br />
              - A great product offers vastly more value than its cost.
              <br />
              - Be good, have fun, learn something. (I stole this one from my mom)
            </p>
          </div>
          <div className="whenSection">
            <p className="sectionTitle">When</p>
            <p className="sectionContent">
              The Company was formed on January 31st, 2024.
            </p>
          </div>
          <div className="whereSection">
            <p className="sectionTitle">Where</p>
            <p className="sectionContent">
              For business-related inquiries, you may contact The Company through email (preferred) or its mailing
              address.
              <br />
              <br />
              marcus@punchcardco.com
              <br />
              <br />
              155 Willowbrook Blvd
              <br />
              Ste 110 #5400
              <br />
              Wayne, New Jersey
            </p>
          </div>
          <div className="howSection">
            <p className="sectionTitle">How</p>
            <p className="sectionContent"><a href="https://knowyourmeme.com/memes/cash-me-ousside-howbow-dah"
                                             target="_blank" rel="noreferrer">“Cash me outside, how bow dat”</a></p>
          </div>
        </div>
      </div>
  );
}
